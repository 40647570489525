.appointmentPreviewContainer {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: var(--coordinator-white);
}

.appointmentPreviewContent {
  margin: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.nameAndChip {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.donorName {
  color: var(--coordinator-dark-gray);
  font-size: 16px;
  font-weight: 500;
}

.availableAppointmentText {
  color: var(--coordinator-gray);
  font-size: 16px;
  font-weight: 500;
}

.deleteButton {
  height: 90px;
  width: 0;
  font-size: 0;
  background-color: var(--coordinator-secondary);
  display: flex;
  flex-direction: column;
  color: var(--coordinator-white);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;
}

.deleteButtonVisible {
  width: 120px;
  font-size: 14px;
}

.deleteButtonText {
  margin-top: 5px;
  line-height: 17px;
}

.divider {
  border-bottom: 0.5px solid var(--coordinator-divider);
}

.dividerContainer {
  padding: 0 20px;
  background-color: var(--coordinator-white);
}

.popupText {
  font-size: 18px;
  color: var(--coordinator-dark-gray);
}

.deleteAppointmentPopupButtons {
  margin-top: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
