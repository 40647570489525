.appHeader {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  justify-content: space-between;
}

.title {
  flex: 1;
  text-align: center;
  position: fixed;
  width: 100%;
  font-weight: normal;
}

.secondaryTitle {
  color: var(--coordinator-secondary);
  font-size: 20px;
}

.infoTitle {
  color: var(--coordinator-dark-gray);
  font-size: 16px;
}

.logoImage {
  padding-top: 8px;
}

.rightButton {
  z-index: 10;
  padding: 0 5px;
}

.stickyComponent {
  border-top: 1px solid var(--coordinator-divider);
}

.leftButton {
  z-index: 10;
}

.listItem {
  min-width: 50vw;
  padding-bottom: 10px;
}

.listItemTitle {
  color: var(--coordinator-dark-gray);
  padding-left: 10px;
}

.selectedTitle {
  font-weight: 700;
}

.version {
  font-size: 14px;
  margin-right: 20px;
  padding-bottom: 10px;
}

.zmLogoImage {
  height: 84px;
  width: 80px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 69px;
}

.menuBack {
  padding-top: 10px;
}
