.fieldsBox {
  background-color: var(--color-white);
  padding: 20px 30px 30px 30px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  margin-bottom: 20px;
}

.fullRowField {
  grid-column: span 2;
}

.addAppointmentScreenContent {
  background-color: rgb(236, 235, 235);
  display: flex;
  flex-direction: column;
}

.appointmentsList {
  background-color: var(--color-white);
  padding: 20px 0 84px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.subtitle {
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 6px;
}

.subtitleText {
  color: var(--coordinator-primary);
  font-weight: 500;
  font-size: 18px;
  width: 90%;
  margin-inline-start: 20px;
}

.noAppointmentsText {
  font-size: 16px;
  line-height: 19px;
  color: var(--coordinator-dark-gray);
  text-align: right;
  width: 100%;
  margin-top: 20px;
  margin-inline-start: 50px;
}

.dateTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--coordinator-dark-gray);
  padding: 20px 20px 10px 0;
}

.actionFooter {
  position: fixed;
  width: 100vw;
  bottom: 0;
}

.actionButtons {
  background-color: var(--color-white);
  display: grid;
  padding: 20px 30px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.15);
}
