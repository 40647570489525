// add global styling here

:root {
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  direction: rtl;
  height: 100%;
}

body {
  background-color: var(--color-bg);
  color: var(--color-text);
  caret-color: transparent; /* Hides blinking cursor */
  -webkit-user-select: none; /* Prevents selecting text */
  -webkit-tap-highlight-color: transparent; /* Prevents blue highlight when pressing */

  font-family: var(--font-family-content);
  font-weight: var(--font-content-weight-normal);
  font-size: var(--text-size-md);
  line-height: var(--font-line-height-normal);
  -webkit-font-smoothing: antialiased;

  direction: var(--text-direction);
}
