.pickerLabel {
  margin-bottom: 5px;
  margin-inline-start: 5px;
  color: var(--coordinator-dark-gray);
  font-size: 16px;
}

.pickerButtons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.pickerButtonContainer {
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.pickerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 44px;
  color: var(--coordinator-dark-gray);
  border: 1px solid var(--coordinator-light-gray);
  border-radius: 4px;
}

.selectedButton {
  min-width: 78px;
  height: 42px;
  color: var(--coordinator-primary);
  font-weight: 500;
  border: 2px solid var(--coordinator-primary);
  background-color: var(--coordinator-selected-green);
}

.storyBloodTypeButton {
  direction: ltr;
}
