.button {
  font-size: 16px;
  min-height: 44px;
  border-radius: 6px !important;
}

.primaryButton {
  background-color: var(--coordinator-primary) !important;
  color: var(--color-white) !important;
}

.secondaryButton {
  background-color: var(--coordinator-secondary) !important;
  color: var(--color-white) !important;
}

.outlinedButton {
  background-color: var(--color-white) !important;
  color: var(--coordinator-dark-gray) !important;
}

.disabledButton {
  background-color: var(--coordinator-light-gray) !important;
  color: var(--coordinator-gray) !important;
}

.textButton {
  font-size: 14px;
  color: var(--coordinator-link);
  text-decoration: underline;
}

.buttonText {
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
}
