//colors-------------------------------------------------------
// See also : src/utils/colors for tsx colors
:root {
  --coordinator-primary: #4caf51;
  --coordinator-bright-green: rgba(162, 212, 94, 0.15);
  --coordinator-selected-green: #a2d45e1f;
  --coordinator-secondary: #c7007d;
  --coordinator-light-pink: #fdf5f8;
  --coordinator-light-black: #1e1f1e;
  --coordinator-dark-gray: #474747;
  --coordinator-gray: #7e7e7e;
  --coordinator-light-gray: #dadee0;
  --coordinator-light: #f6f6f6;
  --coordinator-white: #ffffff;
  --coordinator-error: #cb0027;
  --coordinator-link: #1e88e5;
  --coordinator-divider: #c1c1c1;
  --coordinator-background: #f0f0f0;
  --coordinator-red-chip-background: #fde9f0;
  --coordinator-green-chip-background: #a2d45e26;
  --coordinator-new-slot-background: rgba(240, 240, 240, 0.5);

  --color-primary: #c7007d;
  --color-secondary: #347d8d;
  --color-tertiary: #a5d262;
  --color-light-grey: #e7ecef;
  --color-mid-grey: #5a5a5a;
  --color-black: black;
  --color-white: white;

  --color-bg: #ffffff;
  --color-text: var(--color-black);

  --color-statusbar-bg: var(--color-secondary);
  --color-statusbar-text: white;

  --color-header-bg: var(--color-white);
  --color-header-text: var(--color-black);

  --color-card-bg: var(--color-white);
  --color-card-text: var(--color-black);
}

//fonts--------------------------------------------------------
:root {
  --font-family-titles: "Rubik", serif;
  //--font-title-weight-normal: 400;
  //--font-title-weight-bold: 500;
  //--font-title-weight-bolder: 700;
  //
  --font-family-content: "Rubik", serif;
  //--font-content-weight-normal: 400;
  //--font-content-weight-bold: 700;

  --font-line-height-small: 110%;
  --font-line-height-normal: 160%;

  //copied form here
  //https://codyhouse.co/blog/post/css-custom-properties-vs-sass-variables

  // body font size
  --text-base-size: 1em;

  --text-scale-ratio: 1.1;
  --text-size-xs: calc(
    (1em / var(--text-scale-ratio)) / var(--text-scale-ratio)
  );
  --text-size-sm: calc(var(--text-size-xs) * var(--text-scale-ratio));
  --text-size-md: calc(
    var(--text-size-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-size-lg: calc(var(--text-size-md) * var(--text-scale-ratio));
  --text-size-xl: calc(var(--text-size-lg) * var(--text-scale-ratio));
  --text-size-xxl: calc(var(--text-size-xl) * var(--text-scale-ratio));
  --text-size-xxxl: calc(var(--text-size-xxl) * var(--text-scale-ratio));
}

//layouts-------------------------------------------------------
:root {
  // elements padding/margin
  --layout-distance-small: 10px;
  --layout-distance-normal: 15px;
  --layout-distance-large: 20px;

  --height-header: 100px;
  --height-footer: 25px;
}

//other styling rules------------------------------------------
:root {
  --shadow-normal: rgba(0, 0, 0, 0.2) 0 1px 3px 1px;
  --shadow-string: rgba(0, 0, 0, 0.3) 0 1px 4px 1px;

  --border-radius-normal: 5px;
  --border-radius-strong: 15px;
  --border-radius-stronger: 20px;
  --border-radius-circlular: 30px;

  --transition-speed-fast: 100ms;
  --transition-speed-normal: 200ms;
}

//sizes---------------------------------------------------------
:root {
  --width-phone-landscape: 850px;
}
