.infoBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: white;
  font-size: 16px;
  color: var(--coordinator-dark-gray);
}

.titleContent {
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.icon {
  margin-right: 20px;
}

.values {
  margin-top: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
