.donationDay {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.donationDate {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dayString {
  color: var(--coordinator-dark-gray);
  font-weight: 500;
  font-size: 20px;
}

.weekday {
  margin-inline-end: 10px;
}

.data {
  font-size: 14px;
  color: var(--coordinator-gray);
}

.dataDivider {
  margin: 0 5px;
}

.availableAppointmentsToggle {
  margin: 0 20px 18px 20px;
  font-size: 16px;
  line-height: 19px;
  color: var(--coordinator-dark-gray);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle {
  margin-inline-start: 10px;
}

.slots {
  background-color: var(--coordinator-background);
  height: 100%;
}

.noAppointments {
  padding-top: 150px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: var(--coordinator-gray);
}

.emptySpace {
  height: 80px;
  background-color: var(--coordinator-background);
}
