.coordinatorScreen {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.screenContent {
  flex: 1;
  overflow: auto;
}

.storyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1000px;
  background-color: cyan;
}
