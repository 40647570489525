.divider {
  background-color: rgb(236, 235, 235);
  height: 20px;
}

.content {
  display: grid;
  gap: 25px;
  padding: 0 30px;
  margin-top: 20px;
  background-color: var(--color-white);
}

.buttons {
  display: grid;
  gap: 10px;
}

.subtitle {
  color: var(--coordinator-primary);
  font-weight: 500;
  margin-top: 20px;
}

.donationStartTime {
  text-align: center;
  font-size: 16px;
  color: var(--coordinator-dark-gray);
  padding: 10px;
}
