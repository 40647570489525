.resetPasswordBackButton {
  position: fixed;
  top: 20px;
  right: 20px;

  display: inline-flex;
  align-items: center;
  gap: 10px;

  font-size: 16px;
  color: var(--coordinator-dark-gray);
}

.screen {
  width: 100%;
  padding-top: 50px;
}

.authScreenLogoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img.authScreenLogoImage {
  height: 98px;
}

.screenContent {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoTitle {
  color: var(--coordinator-secondary);
  font-size: 18px;
  font-weight: 700;
}

.subtitle {
  color: var(--coordinator-primary);
  font-weight: 700;
  font-size: 25px;
}

.fields {
  max-width: 400px;
  width: 80%;
  display: grid;
  gap: 16px;
}

.textButton {
  padding-top: 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.loginScreenSecondaryHeader {
  font-weight: 500;
  font-size: 20px;
  color: var(--coordinator-dark-gray);
}

.buttonsContainer {
  display: grid;
  margin-top: 20px;
}

.forgotPasswordBtn {
  color: var(--coordinator-gray);
  padding-top: 25px;
  text-align: center;
}

.resetPassPopupImg {
  width: 150px;
  margin-bottom: 20px;
  color: var(--coordinator-dark-gray);
}

.resetPassPopupTitle {
  font-weight: 500;
}

.resetPassPopupMessage {
  font-size: 16px;
  line-height: 20px;
}

.resetPasswordScreenPopupButton {
  width: 100%;
  margin: 10px auto 0 auto;
}
