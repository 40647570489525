.spinner {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.pickerOverlay {
  position: fixed;
  z-index: 5;
  background: var(--coordinator-divider);
  opacity: 50%;
  height: 100%;
  width: 100%;
}
