.toggleContainer {
  width: 35px;
  height: 20px;
  position: relative;
  background-color: var(--coordinator-light-gray);
  border-radius: 20px;
  cursor: pointer;

  transition: background-color 100ms ease-out;
}

.toggleContainerEnabled {
  background-color: var(--coordinator-primary);
}

.toggleCircle {
  position: absolute;
  top: 2px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 22px;
  background-color: var(--color-white);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15), 0 2px 1px rgba(0, 0, 0, 0.05);

  transition: left 100ms ease-out, right 100ms ease-out;
}

[dir="rtl"] .toggleCircle {
  left: unset;
  right: 3px;
}

.toggleEnabled {
  left: 16px; /* 35 - 3 - 16*/
}

[dir="rtl"] .toggleEnabled {
  left: unset;
  right: 16px;
}
