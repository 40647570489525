.listHeader {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--coordinator-background);
}

.time {
  flex: 1;
  font-size: 16px;
  line-height: 19px;
  color: var(--coordinator-dark-gray);
}

.storyBackground {
  height: 100%;
  background-color: var(--coordinator-background);
}
