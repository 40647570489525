.newSlotContainer {
  background-color: var(--coordinator-new-slot-background);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
}

.slotElements {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: var(--coordinator-dark-gray);
}

.delete {
  cursor: pointer;
}
